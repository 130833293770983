<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="bg-white ma-auto width-500 radius-20"
			>
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h4 class="color-white">MAFIA NFT 선물</h4>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>
				<div
					class="pa-10-20 flex-row under-line"
				>
					<input
						v-model="item_search.search_value"
						type="text"
						placeholder="친구 검색"
						class="flex-1"
						/>
					<v-icon
						class="cursor-pointer"
						@click="getData"
					>mdi mdi-magnify</v-icon>
				</div>
				<div class="pa-10-20">
					<div
						class="under-line pb-10"
					>카드명 <span class="color-secondary font-weight-bold">{{ nft_card.cartl_nft_kind_name ? nft_card.cartl_nft_kind_name : nft_card.nft_card_name }}</span></div>
					<template
						v-if="items.length > 0"
					>
						<div class="bright_input">
							<input
								v-model="search.nick"
								type="search" placeholder="검색"
							>
						</div>
						<div class="">
							<ul
								v-if="search_list.length > 0"
							>
								<li
									v-for="(account, index) in search_list"
									:key="'item_' + index"
								>
									<div class="bright_radio">

										<input
											v-model="item.friend_member_number"
											type="radio" name="follower"
											:value="account.friend_member_number"

											:id="'follower-' + account.friend_member_number"
										>
										<label
											:for="'follower-' + account.friend_member_number">{{  account.nickname  }}</label>
									</div>
								</li>
							</ul>
							<div
								v-else
								class="none"
							>검색된 친구가 없습니다</div>
						</div>
					</template>

					<div
						v-else
						class="none mt-30"
					>선물 가능한 친구가 없습니다</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia025'
	,
	components: {PopupLayer},
	props: ['nft_card', 'user']
	, data: function(){
		return {
			program: {
				name: 'NFT 선물'
			}
			, type: this.$route.params.type ? this.$route.params.type: 'follower'
			, items: []
			, search_list: []
			, item: {
				friend_member_number: ''
			}
			, item_search: {
				search_value: ''
			}
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_friends_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.friend_list
					this.search_list = result.data.friend_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,doSearch: function(call){
			// let call = this.search.nick
			let self = this
			this.search_list = []

			this.items.forEach(function(item){
console.log(call + ' : ' + item.nickname)
				if(call == '' || item.nickname.indexOf(call) > -1){
					self.search_list.push(item)
				}
			})

			console.log(this.search_list)
		}
		,click: function(){
			console.log('this.item', this.item)
			if(!this.item.friend_member_number){
				this.$bus.$emit('notify', { type: 'error', message: '선택된 친구가 없습니다.'})
				return false
			}else{
				this.$emit('click', this.item)
			}
		}
	}
	,created() {
		this.getData()
	}
}
</script>
