<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class=" ma-auto width-400 radius-20"
			>
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h4 class="color-white">MAFIA NFT 스테이킹</h4>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>
				<div
					class="bg-white pa-20"
				>
					<div
						class="bg-logo text-center"
					>
						<div class="top-line pt-20">
							<div
								v-if="item.stakg_fg != 'Y'"
							>
								<strong class="size-px-16">스테이킹을 하시겠습니까?</strong>

								<p class="mt-10">등급에 따라 채굴되는 양이 다릅니다.</p>
							</div>
							<div
								v-else
							>
								스테이킹을 해제 하시겠습니까?
							</div>
						</div>
					</div>

					<div class="mt-30 text-center">
						<button class="btn-inline btn-dark-gray radius-20 mr-10" @click="$emit('cancel')">취소</button>
						<button class="btn-inline btn-primary radius-20" @click="$emit('click')">확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia033-47'
		, props: ['item']
		, components: { PopupLayer}
		, data: function(){
			return {

			}
		}
	}
</script>