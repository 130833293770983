<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="bg-white ma-auto width-400 radius-20"
			>
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h4 class="color-white">MAFIA NFT 출금</h4>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>
				<div class="pa-20">
					<div
						class="position-relative"
					>
						<img
							:src="nft_card.cartl_nft_img_url ? nft_card.cartl_nft_img_url : nft_card.nft_card_img_url"
							:alt="nft_card.name"
							@error="$event.target.src=require('@/assets/image/@noimage.png')"
							class="width-100 radius-20"
						/>
						<div
							class="position-badge-30"
						>
							<span class="badge_40 " :class="'badge_40_' + nft_card.type "><em class="hide">music</em></span>
							<span class="badge_40 badge_40_capo hide"><em class="hide">capo</em></span>
						</div>
					</div>

					<div class="mt-30">
						<input
							v-model="receive_wallet_address"
							type="text"
							placeholder="출금 주소 입력"
							class="input-box radius-20 pa-10-20"
							@change="getTransCommis"
						>
					</div>
					<div class="mt-20 flex-row justify-space-between">
						<span class="">출금 가스비</span>
						<strong class="">{{  tranmsn_commis }}<em class="pl-5 unit">klay</em></strong>
					</div>

					<div
						class="text-center"
					>
						<button class="btn-inline btn-dark-gray radius-20 mr-10" @click="$emit('cancel')">취소</button>
						<button class="btn-inline btn-primary radius-20" @click="toPin">출금신청</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia024'
	, props: ['nft_card', 'user']
	, components: {PopupLayer}
	, data: function(){
		return {
			tranmsn_commis: 0
			,receive_wallet_address: ''
		}
	}
	, methods: {
		getTransCommis: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.get_trans_commis
					,data: {
						blockchain_div_code: 'BC00100001'
						, coin_token_code: 'BC00200003'
						, member_number: this.user.member_number
						, receive_wallet_address: this.receive_wallet_address
					}
					,type: true
				})

				if(result.success){
					this.tranmsn_commis = result.data.tranmsn_commis
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, toPin: function(){
			if(!this.receive_wallet_address){
				this.$bus.$emit('notify', { type: 'error', message: '출금지갑 주소를 입력하세요'})
			}else{
				this.$emit('click', this.receive_wallet_address)
			}
		}
	}
	, created() {
		console.log('this.nft_card', this.nft_card)
	}
}
</script>